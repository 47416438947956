import React from "react"

import SectionHeader from "../components/section-header"
const Contact = () => {
  return (
    <section className="py-16 ">
      <SectionHeader name="Contact" />
      <h3 className="text-black font-regular text-xl pt-4 sm:text-2xl">
        Interested in working together?
        <br />
        Feel free to contact me for any project or collaboration.
      </h3>

      <h4 className="text-gray-800 select-all underline font-bold tracking-wider py-2 ">
        laimgmarco@gmail.com
      </h4>
    </section>
  )
}

export default Contact
