import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Contact from "../components/contact"
import Project from "../components/project"

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const siteDescription = data.site.siteMetadata.description

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" description={siteDescription} />
      <Hero />
      <Project />
      <Contact />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
