import React, { useRef, useEffect } from "react"
import { gsap, Power4 } from "gsap"

const Hero = () => {
  const hero = useRef(null)

  useEffect(() => {
    let tl = gsap.timeline({})
    //Remove initial flash
    gsap.to(hero.current, { duration: 0, visibility: "visible" })

    //Content vars
    const headlineFirst = hero.current.children[0].children[0]
    const headlineSecond = headlineFirst.nextSibling
    const textP1 = hero.current.children[1].children[0]
    const textP2 = textP1.nextSibling.children[0]

    const headline = [
      headlineFirst.children[0],
      headlineSecond.children[0],
      textP1.children[0],
      textP2,
    ]

    tl.from(headline, {
      duration: 1,
      yPercent: 100,
      ease: Power4.easeOut,
      stagger: 0.1,
    })
  }, [])

  return (
    <header ref={hero} className="py-16 invisible ">
      <h1 className="font-bold text-black tracking-wide text-6xl md:text-7xl ">
        <div className="overflow-hidden">
          <div>Hello,</div>
        </div>
        <div className="overflow-hidden">
          <div>I am Marco</div>
        </div>
      </h1>

      <p className="pt-4 font-semibold text-base text-black md:tracking-wide">
        <div className="overflow-hidden">
          <div>Web developer based in Vancouver,BC</div>
        </div>
        <div className="overflow-hidden">
          <div>I design & build interactive experience on web</div>{" "}
        </div>
      </p>
    </header>
  )
}

export default Hero
