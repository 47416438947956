import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import SectionHeader from "../components/section-header"
import { FaArrowRight } from "react-icons/fa"

const Project = () => {
  const data = useStaticQuery(graphql`
    query project {
      site {
        siteMetadata {
          title
        }
      }
      allMdx {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            order
            title
            stack
            description
            featuredImageAlt
            githubLink
            demoLink
            featuredImage {
              id
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)
  const posts = data.allMdx.nodes

  return (
    <section>
      <SectionHeader name="Project" />
      <ol className="py-8">
        {posts
          .sort((a, b) => a.frontmatter.order - b.frontmatter.order)
          .map(post => {
            const order = post.frontmatter.order
            const title = post.frontmatter.title || post.fields.slug
            const stack = post.frontmatter.stack
            const description = post.frontmatter.description
            const image = getImage(
              post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            )
            const imgAlt = post.frontmatter.featuredImageAlt
            const githubLink = post.frontmatter.githubLink
            const demoLink = post.frontmatter.demoLink

            return (
              <li
                key={order}
                className="flex flex-col w-full mb-8  sm:flex-row"
              >
                <div className=" w-full">
                  <GatsbyImage image={image} alt={imgAlt} />
                </div>
                <div className="w-full flex flex-col sm:pl-4 ">
                  <div className="text-black pt-4 sm:pt-0">
                    <p className=" text-sm font-medium text-gray-600 pt-4">
                      {stack}
                    </p>

                    {/* Title */}

                    <h1 className="text-4xl font-extrabold pt-2 highlight ">
                      {title}
                    </h1>

                    <p className=" text-lg font-medium pt-2 ">{description}</p>
                  </div>
                  <div className="mt-4">
                    <a
                      className="flex items-center bg-gray-500 hover:bg-gray-700 text-white text-xs font-semibold py-2 px-4 rounded max-w-max"
                      href={demoLink}
                      alt="Live Demo"
                    >
                      See Website
                      <FaArrowRight className="ml-1" />
                    </a>
                  </div>
                </div>
              </li>
            )
          })}
      </ol>
    </section>
  )
}

export default Project
