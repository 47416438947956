import React, { useRef, useEffect } from "react"
import { gsap } from "gsap"
import { FiArrowUpRight } from "react-icons/fi"
const SectionHeader = ({ name }) => {
  const wrapper = useRef(null)
  const line = useRef(null)

  useEffect(() => {
    let tl = gsap.timeline()
    gsap.to([line.current, wrapper.current.children], { duration: 2 })
    tl.from(line.current, {
      scaleX: 0,
      transformOrigin: "left center",
    })
    tl.from(wrapper.current.children, { duration: 0.5, yPercent: 100 })
  }, [])

  return (
    <>
      <div ref={wrapper} className="overflow-hidden">
        <div className="flex items-center">
          <FiArrowUpRight className="font-medium text-black text-2xl" />
          <h2 className="font-medium text-black text-xl">{name}</h2>
        </div>
      </div>
      <div
        ref={line}
        className="w-full h-0.5 bg-gray-800 max-w-screen-lg my-2"
      />
    </>
  )
}

export default SectionHeader
